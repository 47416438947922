/**
 * Para gerar algo em negrito basta acrescentar ** em volta da frase **
 * lembre-se o nome informado entre {} na mensagem deve ser a mesma chave passada dentro dos 'items'
 * exemplo: {
    howMany: {
      one: { gender: { male: 'vazio', female: 'vazia' } },
      many: { gender: { male: 'vazios', female: 'vazias' } }
    }
  }
 */
export const en_US = {
  'comment': 'comment',
  'event_date': 'date of occurrence',
  'history': 'history',
  'history_animal': 'animal event history',
  'fall_device_alert': 'fall alert',
  'length_unit': 'distance',
  'date_entrance_farm_weight': 'entry weighing date',
  'register_weighing': 'register weighing',
  'illness_alert': 'disease alert',
  'childbirth_alert': 'birth alert',
  'occurrence_date': 'occurrence date',
  'alert_type': 'alert type',
  'birth_windows': 'birth windows',
  'final_date': 'final date',
  'initial_date': 'initial date',
  'registration_date': 'registration date',
  'pregnancy': 'pregnancy',
  'birth_window': {
    howMany: {
      one: 'birth window',
      many: 'birth windows',
    },
  },
  'delete_birth_window': 'remove birth window',
  'edit_birth_window': 'edit birth window',
  'expected_period': 'expected period for childbirth',
  'status_is_pregnant': 'pregnant animal',
  'register_pregnancy': 'register calving window',
  'permanency_time': 'lenght of stay',
  'entrance_date': 'entry date',
  'departure_date': 'departure date',
  'using_structures': 'using structures',
  'structure_area': 'structure area',
  'datagrid_records_info': 'record {from} to {to} of {total}',
  'name_earring': 'name or earring',
  'status_no_movement': 'No recent movement',
  'device_process': 'Device in the process of moving',
  'status_error': 'error',
  'status_success': 'sucess',
  'status_pending': 'pending',
  'archive': 'archive',
  'archives': 'archives',
  'repository': 'Repository',
  'precision': 'precision',
  'accuracy_for_recording': 'accuracy for recording monitoring points',
  'zero_steps_alert_interval': 'tolerance for missing steps alert',
  'edit_information': 'edit information',
  'zero_steps_alert': 'absence of steps',
  'response_sent_successfully': 'response sent successfully',
  'record_information': 'record information',
  'clear_filter': 'clear filter',
  'has_been_removed': 'this animal has been removed from the system!',
  'death': 'death',
  'theft': 'theft',
  'cadastral_error': 'cadastral error',
  'only_monitored': 'only Monitored',
  'displacement_word': 'displacement',
  'not_found_default_title': 'page not found',
  'go_back': 'go back',
  'not_found_default_message':
    "We noticed you got lost, don't worry, we will help you find the right path",
  'position_selected': 'position selected',
  'move_batch_to_lot': 'move lot "{piquete}" to batch',
  'category_of_animals': 'Category of animals',
  'link': 'link',
  'unlink': 'unlink',
  'no_movement': 'no movement',
  'controls': 'controls',
  'batch_exchange': '**Batch change performed.** The batch was allocated in **{item}**, on: {time}',
  'calf': 'calf',
  'yearling': 'yearling',
  'go_to_animal': 'go to animal',
  'device': 'device',
  'notifications': 'notifications',
  'external_alert_profile': 'external escape alert (farm boundaries)',
  'internal_alert_profile': 'internal escape alert (lot)',
  'plot_alert_profile': 'alert plot movement (lot)',
  'alert_activated_message': '{item} successfully activated',
  'alert_deactivated_message': '{item} successfully deactivated',
  'external_alert': 'external escape alert (farm boundaries)',
  'external_alert_accuracy': 'Accuracy of points for external escape (farm boundaries)',
  'internal_alert': 'internal escape alert (lot)',
  'internal_alert_accuracy': 'Accuracy of points for internal escape (lot)',
  'plot_alert': 'plot movement alert (lot)',
  'plot_alert_accuracy': 'Accuracy of points for Batch Movement Alert (lot)',
  'alerts': 'alerts',
  'average_weight': 'average weight',
  'total_weight': 'total weight',
  'user_profile': 'user profile',
  'category': 'category',
  'categories': 'categories',
  'filters': 'filters',
  'maleCalf': 'male calf',
  'femaleCalf': 'female calf',
  'maleYearling': 'male yearling',
  'femaleYearling': 'female yearling',
  'steer': 'steer',
  'cow': 'cow',
  'bull': 'bull',
  'totalArea': 'grazing area {unit}',
  'totalCattles': 'total herd',
  'devicedCattles': 'monitored animals',
  'percentDevicedCattles': 'percentage of cattle with devices',
  'percentageUsageArea': 'capacity ratio {unit}',
  'details': 'details',
  'battery': 'battery',
  'batt_level': 'battery level',
  'results_found': '{item} results found',
  'heifers': 'heifers',
  'calves': 'calves',
  'day': 'day',
  'days': 'days',
  'maximum_age': 'maximum age {item} {time}',
  'growth_stage': 'growth stage',
  'monitoring_behavior': 'monitoring and behavior',
  'packets': 'data packets',
  'age': 'age',
  'date_entrance_farm': 'date of entry into the farm',
  'date_last_weigh_in': 'date of last weigh-in',
  'weight_last_weigh_in': 'weight at last weighing',
  'entrance_weight': 'entrance weight',
  'bnd_registration_date': 'BND registration date',
  'sisbov_number': 'SISBOV number',
  'male_type': 'tipo de macho',
  'interval': 'interval',
  'minutes': '{prefix} minute(s) {sufix}',
  'hours': '{prefix} hours {sufix}',
  'packet_send_frequency': 'packet send frequency',
  'packet_send_frequency_message':
    'The longer the interval, the lower the battery consumption. The lower battery consumption increases the lifetime of the device.',
  'lot_entry': 'lot entry',
  'current_lot': 'current lot',
  'loading': 'loading',
  'updated': 'updated',
  'no-data': 'sem dados',
  'exporting': 'exporting',
  'plot_area_total': 'total area of ​​the plot: {area} {measure}',
  'polygon_area_total': 'total area of ​​the polygon: {area} {measure}',
  'map': 'map',
  'property_boundaries': 'property boundaries',
  'property_boundary': 'property boundary',
  'movimentation': 'movimentation',
  'steps': 'steps',
  'monitoring': 'monitoring',
  'neutral_zone': 'neutral_zone',
  'kg': 'kilogram (kg)',
  'mi': 'miles (mi)',
  'km': 'kilometers (km)',
  'm': 'meters (m)',
  'ha': 'hectare (ha)',
  'm2': 'square meters (m²)',
  'weight': 'weight',
  'length': 'length',
  'measurement_units': 'measurement units',
  'lot_type': 'lot type',
  'animal-handling-area': 'animal handling area',
  'grazing-area': 'grazing area',
  'preservation-area-app': 'preservation-area-app',
  'nursery': 'nursery',
  'shed-warehouse': 'shed-warehouse',
  'lake-weir-dam': 'lake-weir-dam',
  'corral': 'corral',
  'feeding-lane': 'feeding-lane',
  'forestry-production': 'forestry-production',
  'headquarters-residence': 'headquarters-residence',
  'is_on_line': 'online',
  'gateway': 'gateway',
  'structure_help_text':
    'To select the location, click (double click) on the location of the structure on the map below.',
  'shelter': 'shelter',
  'trough': 'trough',
  'feeder': 'feeder',
  'salt-trough': 'salt trough',
  'structure': 'structure',
  'structure_many': 'structures',
  'structure_type': 'structure type',
  'gateway_type': 'gateway type',
  'no_matching_results_found': 'no matching results found',
  'device_moved': 'Device moved successfully',
  'application_target': 'target application',
  'application_name': 'application name',
  'moreSixHour': 'no update (+6h)',
  'moreTwelveHours': 'no update (+12h)',
  'move_to': 'move to',
  'move_device_to': 'move device {item} to',
  'access_device': 'access device',
  'linked': {
    gender: {
      male: '{item} linked',
      female: '{item} linked',
    },
  },
  'unlinked': '{item} unlinked',
  'linked_to': 'linked to',
  'link_device': 'link device {item}',
  'unlink_device': 'unlink device {item}',
  'lat': 'latitude',
  'lng': 'longitude',
  'alt': 'altitude',
  'batt': 'battery',
  'application': 'application',
  'devices': {
    howMany: {
      one: 'device ',
      many: 'devices ',
    },
  },
  'generic_error': 'Tried an error in your request, again later.',
  'pt-BR': 'portuguese (PT-BR)',
  'en-US': 'english (EN-US)',
  'theme': 'theme',
  'dark': 'dark',
  'light': 'light',
  'language': 'language',
  'passwords_and_security': 'passwords and security',
  'passwords': {
    howMany: {
      one: 'password',
      many: 'passwords',
    },
  },
  'home': 'home',
  'new_password': 'new password',
  'current_password': 'current password',
  'password_confirmation': 'password confirmation',
  'personal_information': 'personal information',
  'register_item': 'register {item}',
  'movement_item': '{item} movement',
  'color': 'color',
  'area': 'area',
  'lots': 'lots',
  'search': 'search',
  'pessoa_fisica': 'Pessoa Física', //TODO
  'pessoa_juridica': 'Pessoa Jurífica', //TODO
  'access_farm': 'access Fazenda',
  'password_recovery': 'password recovery',
  'password_recovery_text': 'Are you sure you want to request the registration of a new password?',
  'password_recovery_success':
    'Request sent successfully. Ask the user to check their email box and complete the registration by creating their password.',
  'all_item': 'all {item}',
  'item_selected': 'selected {item}',
  'no_access': 'no access',
  'repeat_item': 'repeat {item}',
  'city': 'city',
  'state': 'state',
  'country': 'country',
  'localization': '{item} localization',
  'dashboard': 'dashboard',
  'account': 'account',
  'date': 'date',
  'birth_date': 'birth date',
  'root': 'root',
  'farm': 'farm',
  'property': {
    howMany: {
      one: 'property ', //DONT REMOVE SPACE
      many: 'properties ', //DONT REMOVE SPACE
    },
  },
  'herd': 'herd',
  'breed': 'breed',
  'animal': {
    howMany: {
      one: 'animal {item}',
      many: 'animals {item}',
    },
  },
  'batch': {
    howMany: {
      one: 'batch {item}',
      many: 'batches {item}',
    },
  },
  'role_label': {
    howMany: {
      one: 'role',
      many: 'roles',
    },
  },
  'inform': 'enter the {item}',
  'select': 'select {item}',
  'name': '{item} name',
  'cancel': 'cancel',
  'confirm': 'confirm',
  'context': 'context',
  'sign_out': 'sign out',
  'plan': {
    howMany: {
      one: 'plan {item}',
      many: 'plans {item}',
    },
  },
  'module': {
    howMany: {
      one: 'module',
      many: 'modules',
    },
  },
  'type': 'type',
  'update': 'update {item}',
  'remove': 'delete {item}',
  'register': 'register {item}',
  'create': 'create {item}',
  'new': 'new {item}',
  'new_female': 'nova {item}',
  'functionality': {
    howMany: {
      one: 'functionality',
      many: 'functionalities',
    },
  },
  'to_view': 'to view',
  'profile': 'profile',
  'setting': 'settings {item}',
  'gender': 'gender',
  'male': 'male',
  'female': 'female',
  'management': '{item} management',
  'description': 'description',
  'success_message': 'Your request has been fulfilled.',
  'success_register': '{item} registered successfully',
  'success_remove': '{item} removed successfully',
  'success_discard': '{item} discarded successfully',
  'confirm_remove': 'Are you sure you want to remove the {item} **"{name}"**?',
  'confirm_unlink': 'Are you sure you want to unlick the {item} **"{name}"**?',
  'confirm_discard': 'Are you sure you want to discard the {item} **"{earring} - {name}"**?',
  'copyright': 'All rights reserved.',
  'user': {
    howMany: {
      one: 'user {item}',
      many: 'users {item}',
    },
  },
  'email': 'e-mail',
  'goal': 'goal',
  'earring': 'earring',
  'phone': 'phone',
  'role_id': 'role',
  'localization_help_text':
    'Enter the latitude and longitude of your property, or click (double click) on the property location on the map below.',
  'email_is_exists':
    'it is not possible to register this user, the email is already being used by another user',
  'phone_is_exists':
    'it is not possible to register this user, the phone is already being used by another user',
  'email_validation_failed':
    'there is a problem with the email provided, please check and try again.',
  'confirmed_validation_failed': 'confirmation email does not match.',
  'minLength_validation_failed':
    'there was a problem with the value entered in the field: **{item}** - {rule}',
  'minLength': 'minimum of characters: {item}',
  'maxLength': 'maximum of characters: {item}',
  'confirmed': 'confirmed',
  'last_access': 'last access',
  'last_activity': 'last activity',
  'E_INVALID_AUTH_PASSWORD': 'Invalid email or password',
  'required': 'this field is required',
  'email_invalid': 'the input is not valid E-mail!',
  'email_required': 'please input your E-mail!',
  'filterTitle': 'Filter menu',
  'filterConfirm': 'OK',
  'filterReset': 'Reset',
  'filterEmptyText': 'No filters',
  'filterCheckall': 'Select all items',
  'filterSearchPlaceholder': 'Search in filters',
  'emptyText': 'No data',
  'selectAll': 'Select current page',
  'selectInvert': 'Invert current page',
  'selectNone': 'Clear all data',
  'selectionAll': 'Select all data',
  'sortTitle': 'Sort',
  'expand': 'Expand row',
  'collapse': 'Collapse row',
  'triggerDesc': 'Click to sort descending',
  'triggerAsc': 'Click to sort ascending',
  'cancelSort': 'Click to cancel sorting',
  'responsible': 'responsible',
  'name_is_exists':
    'there is already a role in the context {context} with **identical name**. Change the name and repeat the operation.',
  'date_created': 'created at',
  'unique_validation_failure':
    'there is already another animal registered with **{item}** informed. Check again and/or change to complete registration.',
  'moduleAccess': 'access modules',
  'dateFormat': 'YYYY-MM-DD',
  'datetimeFormat': 'YYYY-MM-DD HH:mm',
  'error': '**{item}** - {rule}',
  'lot_created': '{date} - Creation of **{plot}**',
  'lot_moved_between': '{date} - Moving plot **{origin}** to **{destine}**',
  'lot_moved_in': '{date} - Entrance of **{plot}** in **{destine}**',
  'lot_moved_out': '{date} - Output from **{plot}**',
  'see_details': 'See details',
  'device_name': 'Device {name}',
  'version': 'Version',
  'copy_data': 'Copy Data',
  'check_all': 'Check All',
  'batch_behavior': 'Batch behavior',
  'movement_history': 'Movement history',
  'monitored': {
    howMany: {
      one: 'monitored',
      many: 'monitored',
    },
  },
  'batch_entry': 'batch entry',
  'displacement': 'Accumulated displacement of the lot (24 hours)',
  'displacement_hour': 'Hourly batch displacement',
  'vinculed_at': 'Associated with:',
  'status': 'Status',
  'data_reception': 'Data reception',
  'last_update': 'last update',
  'link_history': 'Link history',
  'device_linked': '{date} - Linked to the animal **{cattle}**',
  'device_unlinked': '{date} - Unlinked from the animal **{cattle}**',
  'device_entry': '{date} - Entrance to the farm **{farm}**',
  'device_exit': '{date} - Leaving the farm **{farm}**',
  'device_between': '{date} - Moved from the farm **{oldFarm}** to **{farm}**',
}
